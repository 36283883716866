.from-companies {
    background-image: url("/front/images/Elportal/from-companies.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    filter: drop-shadow(0 10px 50px rgba(0,0,0,.16));
    padding-top: 30px;
    padding-bottom: 35px;
    margin-bottom: 30px;
    &__top-text {
        background-color: #fff;
        padding: 17px;
        color: #1F1F1F;
        font-size: 28px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 34px;
    }
    &__icons {
        display: flex;
        justify-content: space-between;
        padding: 0 34px;
        margin-bottom: 38px;
        &-item {
            width: 86px;
            height: 86px;
            border-radius: 50%;
            backdrop-filter: blur(30px);
            background-color: rgba(255, 255, 255, 0.5); 
            display: flex;
            justify-content: center;
            align-items: center;
            filter: drop-shadow(0 10px 15px rgba(0,0,0,.16));
        }
    }
    &__btn {
        font-size: 15px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0.050em;
        text-transform: uppercase;
        background-color: #fff;
        border-radius: 20px;
        max-width: 266px;
        width: 100%;
        border-radius: 142px;
        padding: 20px;
        text-align: center;
        margin: 0 auto;
    }
}