.science-clubs-module {
    background: linear-gradient(45deg, #1f1f1f 0%,#cd1719 100%);
    padding: 18px 22px 22px 22px;
    display: block;
    cursor: pointer;
    margin-bottom: 30px;
    &__icon {
        width: 124px;
        height: 124px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F62023;
        border-radius: 50%;
        margin: 0 auto 14px auto;
        filter: drop-shadow(0 30px 60px #1F1F1F);
    }
    &__title {
        font-size: 18px;
        line-height: 45px;
        font-weight: 300;
        letter-spacing: 0.050em;
        color: #fff;
        text-transform: uppercase;
        z-index: 1;
        position: relative;
        text-align: center;
    }
}