.single-article {
    position: relative;
    &__premium {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        position: relative;
        z-index: 3;
        justify-content: center;
        height: 100%;
        min-height: 300px;
        @media(min-width: 640px) {
            justify-content: space-between;
            padding: 30px 15px;
        }
        @media(min-width: 768px) {
            padding: 30px 45px;
        }
        @media(min-width: 1025px) {
            padding: 30px 15px;
        }
        @media(min-width: 1200px) {
            padding: 30px 45px;
        }
        &-wrap {
            background-image: url("/front/images/Elportal/subscription/blocked.jpg");
            background-position: center;
            background-size: cover;
            width: 100%;
            margin: 0 auto 30px;
            box-shadow: 0 17px 27px rgba(0,0,0,0.4);
            position: relative;
            max-width: 320px;
            @media(min-width: 640px) {
                max-width: 770px;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0.96;
                background:-moz-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                background:-webkit-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                background:-o-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#CD1719', endColorstr='#1F1F1F', GradientType=1 );
                background:-ms-linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
                background:linear-gradient(45deg, rgba(31, 31, 31, 1) 0%, rgba(205, 23, 25, 1) 100%);
            }
        }
        &-paper {
            display: none;
            @media(min-width: 640px) {
                display: block;
            }
            img {
                box-shadow: 0 20px 99px rgba(0,0,0,0.4);
            }
        }
        &-right {
            max-width: 206px;
            text-align: center;
            color: #ffffff;
            width: 100%;
        }
        &-text {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 30px;
        }
        &-btn {
            border-radius: 142px;
            box-shadow: 0 12px 64px rgba(0,0,0,0.56);
            color: #CD1719;
            cursor: pointer;
            background-color: #ffffff;
            width: 100%;
            max-width: 206px;
            margin: 0 auto;
            min-height: 58px;
            text-transform: uppercase;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0.05em;
        }
    }
    &__ending-text {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 30px;
        a {
            color: #CD1719;
        }
    }
    &__title {
        font-size: 26px;
        line-height: 33px;
        font-weight: 700;
        margin-bottom: 31px;
        letter-spacing: -0.025em;
        margin-top: 0px;
        @media(min-width: 768px) {
            font-size: 36px;
            line-height: 40px;
        }
        @media(min-width: 1024px) {
            font-size: 47px;
            line-height: 53px;
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            h1 {
                margin-top: 0px;
            }
            .single-article__title {

            }
        }
        &--diy {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            span {
                width: 100%;
                margin-bottom: 30px;
                @media(min-width: 576px) {
                    margin-bottom: 0;
                    padding-right: 30px;
                    max-width: calc(100% - 112px);
                }
            }
        }
    }
    &__author {
        padding-bottom: 14px;
        border-bottom: 1px solid #DCDCDC;
        margin-bottom: 16px;
        &-name {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            display: inline-block;
            margin-right: 17px;
        }
        &-category {
            color: #898989;
            font-size: 20px;
            line-height: 24px;
            display: inline-block;
        }
    }
    &__image {
        margin-bottom: 30px;
    }
    &__info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        text-transform: uppercase;
        margin-bottom: 30px;
        &-type {
            font-size: 13px;
            line-height: 15px;
            display: flex;
            align-items: center;
            margin-right: 22px;
            &-title {
                margin-right: 13px;
            }
            &-value {
                text-transform: none;
                &-item {
                    display: inline-block;
                    margin-right: 5px;
                    padding: 5px 3px;
                    background-color: #E8EBEE;
                }
            }
        }
        &-date {
            font-size: 13px;
            line-height: 15px;
            display: flex;
            align-items: center;
            margin-right: 16px;
            text-transform: lowercase;
            img {
                margin-right: 10px;
            }
        }
        &-views {
            font-size: 13px;
            line-height: 15px;
            display: flex;
            align-items: center;
            img {
                margin-right: 10px;
            }
        }
    }
    &__warning {
        background-color: #1F1F1F;
        color: #fff;
        padding: 30px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        @media(max-width: 650px) {
            flex-wrap: wrap;
        }
        &-text {
            font-size: 20px;
            line-height: 32px;
            letter-spacing: -0.025em;
            margin-right: 15px;
            @media(max-width: 650px) {
                font-size: 15px;
                line-height: 24px;
                order: 2;
                max-width: 100%;
                flex: 0 0 100%;
            }
            &-title {
                text-transform: uppercase;
                display: block;
            }
        }
        &-image {
            width: 124px;
            flex: 0 0 124px;
            height: 124px;
            background-color: #CD1719;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width: 768px) {
                width: 100px;
                height: 100px;
                flex: 0 0 100px;
            }
            @media(max-width: 650px) {
                order: 1;
                margin: 0 auto 20px auto;
            }
        }
    }
    &__learn {
        display: flex;
        align-items: center;
        padding: 30px 28px;
        border: 3px solid #1F1F1F;
        margin-bottom: 40px;
        @media(max-width: 768px) {
            padding: 20px;
        }
        @media(max-width: 650px) {
            flex-wrap: wrap;
        }
        &-text {
            font-size: 20px;
            line-height: 32px;
            font-weight: 700;
            letter-spacing: -0.025em;
            margin-right: 15px;
            @media(max-width: 768px) {
                font-size: 16px;
                line-height: 25px;
            }
            @media(max-width: 650px) {
                max-width: 100%;
                flex: 0 0 100%;
                order: 2;
            }
        }
        &-image {
            width: 124px;
            height: 124px;
            flex: 0 0 124px;
            border-radius: 50%;
            background-color: #CD1719;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width: 768px) {
                width: 100px;
                height: 100px;
                flex: 0 0 100px;
            }
            @media(max-width: 650px) {
                order: 1;
                margin: 0 auto 20px auto;
            }
        }
    }
    &__introtext {
        font-size: 16px;
        line-height: 25px;
        font-weight: 700;
        letter-spacing: -0.025em;
        margin-bottom: 30px;
        @media(min-width:768px) {
            font-size: 20px;
            line-height: 32px;
        }
    }
    &__kit {
        max-width: 125px;
        flex: 0 0 125px;
        margin-left: 25px;
        background-color: #fff;
        width: 125px;
        height: 125px;
        border-radius: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        &-red {
            font-size: 18px;
            line-height: 13px;
            color: #fff;
            letter-spacing: 0.020em;
            background-color: #CD1719;
            padding: 5px;
            margin-bottom: 3px;
        }
        &-text {
            display: block;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.020em;
            text-transform: uppercase;
            max-width: 100%;
            flex: 0 0 100%;
            text-align: center;
        }
        &-right {
            text-align: center;
            display: block;
            background-color: #fff;
            padding: 20px 30px;
            margin-bottom: 30px;
            &-text {
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.020em;
                padding-bottom: 10px;
                margin-bottom: 7px;
                border-bottom: 2px solid #CD1719;
                text-transform: uppercase;
                text-align: left;
            }
            &-image {
                margin-bottom: 16px;
            }
            &-title {
                margin-bottom: 15px;
                font-size: 18px;
                line-height: 23px;
                font-weight: 700;
                text-align: center;
            }
            &-btn {
                font-size: 15px;
                line-height: 18px;
                font-weight: 500;
                letter-spacing: 0.050em;
                text-transform: uppercase;
                color: #fff;
                display: inline-block;
                padding: 20px 30px;
                background-color: #CD1719;
                border-radius: 142px;
            }
        }
    }
    &__data-sheet {
        margin-bottom: 50px;
        max-height: 1000px;
        object {
            width: 100%;
            height: 1000px;
        }
        iframe {
            width: 100%;
            height: 1000px;
        }
        #adobe-dc-view {
            max-width: 100%;
            width: 100% !important;
            // height: 1000px !important;
        }
    }
    &__movie {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        margin-bottom: 50px;
        iframe {
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__block {
        width: 100%;
        background-image: url('/front/images/Elportal/blocked-article-background.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        padding: 51px 30px 42px 30px;
        position: relative;
        text-align: center;
        margin-bottom: 50px;
        box-shadow: 0 17px 27px rgba(0,0,0,.4);
        &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            left: 0;
            height: 174px;
            width: 100%;
            background: linear-gradient(to top, rgba(243,243,243,1) 0%,rgba(255,255,255,0) 100%);
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(45deg, rgba(31,31,31,0.95) 0%,rgba(205,23,25,1) 100%);
            width: 100%;
            height: 100%;
        }
        &--magazine {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 31px 30px 31px 30px;
            @media(max-width: 660px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &-logo {
                z-index: 1;
                position: relative;
                @media(max-width: 660px) {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 17px;
                }
            }
            &-image {
                z-index: 1;
                position: relative;
                @media(max-width: 660px) {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 17px;
                }
                img {
                    filter: drop-shadow(0 20px 99px rgba(#000000,.4));
                    display: block;
                    margin: 0 auto;
                }
            }
            &-text {
                z-index: 1;
                position: relative;
                max-width: 206px;
                &-description {
                    font-size: 16px;
                    line-height: 24px;
                    color: #fff;
                    margin-bottom: 17px;
                }
                &-number {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 700;
                    margin-bottom: 17px;
                    color: #fff;
                }
                &-btn {
                    max-width: 100%;
                    width: 100%;
                    padding: 0 30px;
                    height: 58px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    color: #CD1719;
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: 600;
                    letter-spacing: 0.050em;
                    text-transform: uppercase;
                    border-radius: 142px;
                    filter: drop-shadow(0 12px 64px rgba(#000000,.56));
                    img {
                        margin-left: 11px;
                    }
                }
            }
        }
        &-logo {
            z-index: 1;
            position: relative;
            margin-bottom: 24px;
        }
        &-text {
            z-index: 1;
            position: relative;
            max-width: 300px;
            margin: 0 auto;
            font-size: 18px;
            line-height: 26px;
            color: #fff;
            margin-bottom: 32px;
        }
        &-btn {
            z-index: 1;
            position: relative;
            padding: 20px 44px;
            border-radius: 142px;
            color: #fff;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            line-height: 18px;
            font-weight: 500;
            letter-spacing: 0.050em;
            background-color: #CD1719;
            max-width: 300px;
            margin: 0 auto;
            box-shadow: 0 12px 64px rgba(0,0,0,.56);
            img {
                margin-left: 11px;
            }
        }
    }
    &__fulltext {
        font-size: 15px;
        line-height: 24px;
        // p::selection {
        //     background-color: #EEFF00;
        // }
        // b::selection {
        //     background-color: #EEFF00;
        // }
        // strong::selection {
        //     background-color: #EEFF00;
        // }
        // a::selection {
        //     background-color: #EEFF00;
        // }
        a.imagesInContent {
            display: block;
            img {
                display: block;
                margin: 0 auto;
            }
        }
        @media(min-width: 768px) {
            font-size: 18px;
            line-height: 30px;
        }
        &.hidden {
            max-height: 1200px;
            overflow: hidden;
        }
        h2 {
            font-size: 27px;
            line-height: 35px;
            font-weight: 700;
            margin-bottom: 30px;
            margin-top: 40px;
            @media(max-width: 768px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        p {
            margin-bottom: 30px;
            margin-top: 0px;
            &:after {
                content: "";
                clear: both;
                display: table;
            }
        }
        .yellow {
            background-color: #EEFF00;
        }
        .list-of-items {
            padding: 37px 25px 65px 25px;
            border: 2px solid #1F1F1F;
            margin-bottom: 50px;
            background-color: #fff;
            &.pb-26 {
                padding-bottom: 26px;
            }
            &__title {
                font-size: 20px;
                font-weight: 700;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 50px;
                &.mb-23 {
                    margin-bottom: 23px;
                }
            }
            &__list {
                &--top {
                    .list-of-items__list-item {
                        font-size: 13px;
                        color: #898989;
                        margin-bottom: 13px;
                        &::after {
                            display: none;
                        }
                    }
                }
                &-item {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    &.columns-3 {
                        .list-of-items__list-item-left {
                            flex-grow: 1;
                            z-index: inherit;
                            .list-of-items__list-item-left-value {
                                z-index: 2;
                            }
                        }
                        .list-of-items__list-item-right {
                            z-index: 2;
                        }
                        &::after {
                            z-index: 1;
                            max-width: calc(100% - 45px);
                        }
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        bottom: 8px;
                        right: 0;
                        background-image: url('/front/images/Elportal/dott.png');
                        background-size: 2px 1px;
                        background-repeat: repeat-x;
                        background-position: bottom;
                    }
                    &-number {
                        width: 28px;
                        margin-right: 17px;
                        text-align: center;
                    }
                    &-left, &-right {
                        background-color: #fff;
                        z-index: 1;
                    }
                    &-left {
                        &-value {
                            display: inline-block;
                            z-index: 1;
                            position: relative;
                            background-color: #fff;
                        }
                    }
                }
            }
            &__button {
                background-color: #CD1719;
                color: #fff;
                text-align: center;
                border-radius: 147px;
                padding: 20px;
                margin-top: 33px;
                display: block;
                &-value {
                    display: inline-block;
                    font-size: 18px;
                    color: #fff;
                }
                &-kit {
                    display: inline-block;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.020em;
                    padding: 5px;
                    border: 2px solid #fff;
                    margin-left: 12px;
                    text-transform: uppercase;
                    height: 36px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                }
            }
        }
        a {
            color: #CD1719;
        }
        ul:not([class]) {
            list-style: disc;
            padding-left: 18px;
            max-width: 630px;
            margin-bottom: 55px;
            font-size: 18px;
            line-height: 20px;
            margin-left: 30px;
            li {
                margin-bottom: 16px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }
        ol:not([class]) {
            list-style: decimal;
            padding-left: 18px;
            max-width: 630px;
            margin-bottom: 55px;
            font-size: 18px;
            line-height: 20px;
            margin-left: 30px;
            li {
                margin-bottom: 16px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }
        ul:not([class]),
        ol:not([class]) {
            font-size: 15px;
            line-height: 24px;
            max-width: calc(100% - 30px);
            width: calc(100% - 30px);
            @media(min-width: 768px) {
                font-size: 18px;
                line-height: 30px;
            }
            ul {
                margin-bottom: 32px;
                li {
                    &:first-child {
                        margin-top: 16px;
                    }
                }
            }
        }
        & > img {
            margin-bottom: 50px;
            display: block;
        }
        .shop-avt {
            margin-bottom: 40px;
            background-color: #fff;
            text-align: center;
            padding-bottom: 27px;
            &__title {
                font-size: 18px;
                font-weight: 700;
                padding: 20px 30px;
                background-color: #DCDCDC;
                text-transform: uppercase;
                border-bottom: 3px solid #CD1719;
                text-align: left;
            }
            &__description {
                padding: 25px 30px 30px 30px;
                font-size: 15px;
                line-height: 24px;
                text-align: left;
            }
            &__btn {
                display: inline-block;
                margin: 0 auto;
                background-color: #CD1719;
                padding: 20px 30px;
                border-radius: 142px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 500;
                text-decoration: none;
                box-shadow: 0 12px 30px rgba(205,23,25,.16);
                font-size: 15px;
                line-height: 18px;
                letter-spacing: 0.050em;
            }
        }
        .one-product {
            background-color: #fff;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 30px;
            &__image {
                max-width: 216px;
                flex: 0 0 216px;
                margin-right: 30px;
                @media(max-width: 650px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    img {
                        margin: 0 auto;
                        display: block;
                    }
                }
            }
            &__text {
                max-width: calc(100% - 246px);
                flex: 0 0 calc(100% - 246px);
                padding: 20px 0;
                @media(max-width: 650px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    text-align: center;
                    padding: 20px;
                }
                &-title {
                    font-size: 20px;
                    line-height: 25px;
                    font-weight: 700;
                    margin-bottom: 27px;
                    color: #1F1F1F;
                }
                &-btn {
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 500;
                    letter-spacing: 0.050em;
                    border-radius: 142px;
                    background-color: #CD1719;
                    color: #fff;
                    padding: 13px 22px;
                    text-transform: uppercase;
                    display: inline-block;
                }
            }
        }
        .two-products {
            &__col {
                margin-bottom: 30px;
            }
            &__item {
                background-color: #fff;
                text-align: center;
                &-image {
                    margin-bottom: 30px;
                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
                &-title {
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 700;
                    margin-bottom: 28px;
                }
                &-btn {
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 500;
                    letter-spacing: 0.050em;
                    border-radius: 142px;
                    background-color: #CD1719;
                    color: #fff;
                    padding: 10px 21px;
                    text-transform: uppercase;
                    display: inline-block;
                    margin-bottom: 25px;
                }
            }
        }
        .three-products {
            &__col {
                margin-bottom: 30px;
            }
            &__item {
                background-color: #fff;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                height: 100%;

                &-image {
                    margin-bottom: 15px;
                }
                &-title {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 700;
                    margin-bottom: 15px;
                    max-width: 100%;
                    width: 100%;
                }
                &-btn {
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 500;
                    letter-spacing: 0.050em;
                    border-radius: 142px;
                    background-color: #CD1719;
                    color: #fff;
                    padding: 10px 21px;
                    text-transform: uppercase;
                    display: inline-block;
                    margin: 0 auto 25px auto;
                }
            }
        }
        .formula {
            width: 100%;
            background-color: #FFFFFF;
            border: 1px solid #DCDCDC;
            padding: 30px;
            display: flex;
            justify-content: center;
            margin: 60px 0;
        }
        .code-toolbar {
            .toolbar {
                &-item {
                    &:first-of-type {
                        display: none;
                    }
                }
            }
        }
        pre[class^='language-'], pre[class*=' language-'] {
            code {
                line-height: 0px;
            }
        }
        div[class^='language-'], div[class*=' language-'], pre[class^='language-'], pre[class*=' language-'] {
            counter-reset: line;
            position: relative;
            overflow-x: auto;
            border: 2px solid rgba(31, 31, 31, .15);
            font-family: 'Consolas', monospace;
            margin-bottom: 30px;
            padding: 20px 0;
            &::before {
                content: '';
                background-color: #EBEBEB;
                width: 40px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
            code {
                font-size: 16px;
                counter-increment: line;
                white-space: nowrap;
                display: block;
                min-width: 100%;
                &::before {
                    content: counter(line);
                    width: 40px;
                    display: inline-block;
                    padding-left: 5px;
                    font-size: 13px;
                    opacity: 0.2;
                    margin-right: 30px;
                    padding-right: 5px;
                    text-align: right;
                    box-sizing: border-box;
                }
            }
        }
        .one-image {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 50px 0;
            &__image {
                border: 1px solid #E1E1E1;
                margin-bottom: 9px;
                img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    margin-bottom: 0px;
                }
            }
            &__description {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                width: 100%;
            }
        }
        .four-images {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 30px;
            &__image {
                margin-bottom: 7px;
                img {
                    border: 1px solid #E1E1E1;
                    display: block;
                    margin: 0 auto;
                }
            }
            &__description {
                text-align: center;
                margin-bottom: 25px;
            }
        }
        .two-images {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 30px;
            &__image {
                margin-bottom: 7px;
                img {
                    border: 1px solid #E1E1E1;
                    display: block;
                    margin: 0 auto;
                }
            }
            &__description {
                text-align: center;
                margin-bottom: 25px;
            }
        }
        .image-right {
            float: right;
            border: 1px solid #E1E1E1;
            margin-left: 10px;
            margin-bottom: 30px;
            &__image {
                border: 1px solid #E1E1E1;
                margin-bottom: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &__description {
                font-size: 14px;
                line-height: 20px;
            }
            &-description {
                float: right;
                margin-left: 10px;
                margin-bottom: 20px;
                max-width: 300px;
            }
        }
        .text-gray {
            background-color: #E1E1E1;
            padding: 24px;
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 30px;
        }
        .distinctive-features {
            padding: 25px 30px;
            background-color: #E1E1E1;
            margin-bottom: 30px;
            &__title {
                font-size: 20px;
                line-height: 30px;
                font-weight: 700;
                padding-bottom: 15px;
                border-bottom: 2px solid #D63A3A;
                margin-bottom: 17px;
            }
            &__bottom {
                display: flex;
                justify-content: space-between;
                @media(max-width:650px) {
                    flex-wrap: wrap;
                }
            }
            &__list {
                padding-left: 16px;
                margin-top: 0;
                margin-right: 15px;
                list-style: inherit;
                li {
                    font-size: 16px;
                    line-height: 25px;
                }
            }
            &__image {
                max-width: 200px;
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                @media(max-width:650px) {
                    max-width: 100%;
                }
                &-text {
                    font-size: 11px;
                    line-height: 15px;
                }
            }
        }
        .custom-table {
            margin-bottom: 30px;
            &__title {
                font-size: 15px;
                font-weight: 700;
                background-color: #D63A3A;
                padding: 32px 25px;
                color: #fff
            }
            &__table {
                overflow-y: hidden;
                overflow-x: auto;
                table {
                    border-collapse: collapse;
                    border-spacing: 0;
                    tr:first-child {
                        td {
                            font-weight: 700;
                        }
                    }
                    td {
                        border: 1px solid #707070;
                        border-collapse: collapse;
                        margin: 0;
                        padding: 0;
                        text-align: center;
                    }
                }
            }
        }
        .movie {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            margin-bottom: 50px;
            iframe {
                border: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .toc {
        background-color: #fff;
        margin-bottom: 40px;
        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 3px solid #F3F3F3;
            @media(min-width: 768px) {
                padding: 20px 45px;
            }
            &-prev, &-next {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 700;
                color: #CD1719;
                &-text {
                    display: none;
                    @media(min-width: 768px) {
                        display: inline-block;
                    }
                }
            }
            &-prev {
                img {
                    margin-right: 11px;
                }
            }
            &-title {
                font-size: 16px;
                font-weight: 700;
                color: #1F1F1F;
                padding: 16px 25px;
                border: 2px solid #DCDCDC;
                border-radius: 122px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                white-space: nowrap;
                @media(min-width: 575px) {
                    padding: 16px 45px;
                }
                &-lines {
                    width: 15px;
                    height: 12px;
                    position: relative;
                    margin-right: 11px;
                    &.closed {
                        .toc__top-title-lines-line {
                            &:nth-child(1) {
                                top: 5px;
                                transform: rotate(135deg);
                              }

                            &:nth-child(2) {
                                opacity: 0;
                                left: -60px;
                            }

                            &:nth-child(3) {
                                top: 5px;
                                transform: rotate(-135deg);
                            }
                        }
                    }
                    &-line {
                        display: block;
                        position: absolute;
                        height: 2px;
                        width: 100%;
                        background: #000;
                        border-radius: 9px;
                        opacity: 1;
                        left: 0;
                        transform: rotate(0deg);
                        transition: .25s ease-in-out;
                        &:nth-child(1) {
                            top: 0px;
                          }

                        &:nth-child(2) {
                            top: 5px;
                        }

                        &:nth-child(3) {
                            top: 10px;
                        }
                    }
                }
            }
            &-next {
                img {
                    margin-left: 11px;
                }
            }
        }
        &__list {
            transition: .3s all ease-in-out;
            padding-bottom: 15px;
            position: relative;
            &--lg {
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 85px;
                    left: 0;
                    right: 0;
                    top: calc(50% - 85px);
                    pointer-events: none;
                    background:-moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                    background:-webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                    background:-o-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#FFFFFF', GradientType=1 );
                    background:-ms-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                    background:linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                }
                .toc__list-item {
                    &:nth-child(n + 11) {
                        display: none;
                    }
                }
                &.open {
                    &:after {
                        display: none;
                    }
                    .toc__list-item {
                        display: block;
                    }
                    .toc__list-btn {
                        .closed {
                            display: none;
                        }
                        .open {
                            display: block;
                        }
                    }
                }
            }
            &-wrapper {
                max-width: 654px;
                margin: 0 auto;
                padding: 15px;
                font-size: 15px;
                line-height: 24px;
                transition: .3s all ease-in-out;
                cursor: pointer;
                @media(min-width: 768px) {
                    padding: 28px 0 40px 0;
                }
            }
            &.hidden {
                max-height: 0px !important;
                overflow: hidden;
                .toc__list-wrapper {
                    opacity: 0;
                }
            }
            &-item  {
                display: block;
                margin-bottom: 12px;
                &.active {
                    color: #CD1719;
                    text-decoration: underline;
                }
            }
            &-btn {
                position: relative;
                z-index: 3;
                margin: 0 auto 20px;
                min-height: 51px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                align-items: center;
                text-align: center;
                border-radius: 122px;
                border: 2px solid #DCDCDC;
                width: 100%;
                max-width: 300px;
                font-size: 16px;
                font-weight: 700;
                cursor: pointer;
                .closed {
                    display: block;
                }
                .open {
                    display: none;
                }
            }
        }
    }
    &__gallery {
        margin-bottom: 30px;
        &-col {
            margin-bottom: 5px;
            padding-left: 2.5px;
            padding-right: 2.5px;
        }
        &-row {
            margin-right: -2.5px;
            margin-left: -2.5px;
        }
    }
    &__files {
        margin-bottom: 60px;
        &-wrapper {
            background-color: #fff;
            padding: 24px 32px 36px 32px;
        }
        &-item {
            display: block;
            margin-bottom: 14px;
            img {
                margin-right: 14px;
            }
        }
    }
    &__tags {
        margin-bottom: 21px;
        max-width: 100%;
        width: 100%;
        &-title {
            font-size: 18px;
            font-weight: 700;
            margin-right: 12px;
        }
        &-item {
            display: inline-block;
            background-color: #E8EBEE;
            padding: 5px 3px;
            margin-right: 5px;
            font-size: 15px;
            line-height: 17px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    &__topic-title {
        margin-bottom: 31px;
        &-name {
            font-size: 18px;
            font-weight: 700;
            display: inline-block;
            margin-right: 12px;
        }
        &-value {
            font-size: 15px;
            letter-spacing: 0.025em;
            text-transform: uppercase;
        }
    }
    &__author-bottom {
        display: flex;
        margin-bottom: 30px;
        &-image {
            margin-right: 30px;
            max-width: 109px;
            flex: 0 0 109px;
            img {
                border-radius: 100%;
                max-width: 109px;
                width: 100%;
            }
        }
        &-text {
            max-width: calc(100% - 139px);
            flex: 0 0 calc(100% - 139px);
            &.w-100 {
                max-width: 100%;
                flex: 0 0 100%;
            }
            &-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 25px;
                &-name {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: 700;
                }
                &-contact {
                    border: 1px solid #1F1F1F;
                    border-radius: 193px;
                    font-size: 11px;
                    letter-spacing: 0.075em;
                    padding: 8px 16px;
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 6px;
                        margin-top: 2px;
                    }
                }
            }
            &-description {
                font-size: 15px;
                line-height: 24px;
                margin-bottom: 25px;
            }
            &-more {
                color: #CD1719;
                font-size: 11px;
                letter-spacing: 0.075em;
                text-transform: uppercase;
            }
        }
    }
    &__source {
        margin-bottom: 50px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        &-value {
            color: #CD1719;
            display: inline-block;
        }
        a {
            color: #CD1719;
        }
    }
    &__share {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 50px;
        a {
            color: #fff;
        }
        &-item {
            max-width: 240px;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 18px;
            img {
                margin-right: 10px;
            }
            &.facebook {
                background-color: #3B5998;
            }
            &.twitter {
                background-color: #1DA1F2;
            }
            &.linkedin {
                background-color: #0077B5;
            }
        }
    }
    &__connected {
        margin-bottom: 50px;
        &-item {
            margin-bottom: 30px;
            &-image {
                margin-bottom: 30px;
            }
            &-title {
                font-size: 18px;
                line-height: 22px;
                font-weight: 700;
            }
        }
        .hidden {
            max-height: 0px;
            overflow: hidden;
            transition: .3s all ease-in-out;
            &.show {
                max-height: 1000px;
            }
        }
        &-btn {
            text-align: center;
            &-wrapper {
                font-size: 13px;
                line-height: 16px;
                font-weight: 500;
                background-color: #CD1719;
                border-radius: 142px;
                color: #fff;
                padding: 10px 35px;
                display: inline-block;
                text-transform: uppercase;
                letter-spacing: 0.050em;
                cursor: pointer;
            }
        }
    }
    &__gift {
        background-color: #fff;
        padding: 20px 20px 30px 20px;
        filter: drop-shadow(0 10px 50px rgba(0,0,0,.16));
        margin-bottom: 30px;
        .section-title {
            display: block;
            font-weight: 700;
        }
        &-image {
            img {
                margin: 0 auto 20px auto;
            }
        }
        &-title {
            font-size: 18px;
            line-height: 23px;
            letter-spacing: -0.025em;
            font-weight: 700;
            text-align: center;
        }
    }
    &__deadline {
        background-color: #fff;
        padding: 20px 20px 40px 20px;
        filter: drop-shadow(0 10px 50px rgba(0,0,0,.16));
        margin-bottom: 30px;
        text-align: center;
        position: sticky;
        top: 100px;
        left: 0;
        .section-title {
            display: block;
            font-weight: 700;
            text-align: left;
        }
        &-date {
            font-size: 30px;
            line-height: 38px;
            font-weight: 700;
            letter-spacing: -0.025em;
            margin-bottom: 5px;
        }
        &-text {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 16px;
            letter-spacing: -0.025em;
        }
        &-email {
            font-size: 15px;
            line-height: 18px;
            font-weight: 500;
            text-transform: uppercase;
            color: #fff;
            padding: 20px 30px;
            background-color: #CD1719;
            border-radius: 142px;
            display: inline-block;
            margin-bottom: 20px;
            &.inactive {
                border: 1px solid #707070;
                background-color: transparent;
                color: #898989;
            }
        }
        &-text-email {
            &-title {
                margin-bottom: 10px;
                font-size: 18px;
            }
            &-value {
                color: #CD1719;
                font-size: 18px;
                font-weight: 700;
            }
        }
    }
    &__apply-now {
        font-size: 21px;
        line-height: 26px;
        font-weight: 700;
        width: 100%;
        padding: 42px;
        text-transform: uppercase;
        color: #fff;
        background-color: #CD1719;
        border-radius: 142px;
        display: block;
        text-align: center;
        margin-top: 60px;
        margin-bottom: 20px;
        &.inactive {
            border: 1px solid #707070;
            background-color: transparent;
            color: #898989;
        }
        &-wrapper {
            margin-bottom: 82px;
            text-align: center;
        }
    }
    &__partner {
        @media(max-width: 500px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 15px;
        }
        &-logo {
            img {
                display: block;
            }
        }
        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media(max-width: 500px) {
                flex-wrap: wrap;
            }
        }
        &-title {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
        }
        a {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: #CD1719;
        }
    }
}
.socials-left {
    position: sticky;
    left: 0;
    top: 170px;
    max-width: 68px;
    display: none;
    z-index: 6;
    &__wrapper {
        max-width: 68px;
        position: absolute;
        left: 8px;
        top: 0;
        bottom: 15px;
    }
    &.bottom {
        left: 100%;
        right: 8px;
        top: auto;
        bottom: 38px;
        display: block;
        z-index: 6;
        position: sticky;
        bottom: 10px;
        @media(min-width: 1350px) {
            display: none;
        }
        .socials-left__item {
            margin-left: 0;
            background: #F3F3F3;
            &.share {
                border: none;
                background-color: #1F1F1F;
            }
        }
        &.hidden {
            .socials-left__item {
                opacity: 0;
                transform: translateY(20px);
                &:hover {
                    background-color: #F3F3F3;
                    svg {
                        path {
                            fill: #000;
                        }
                    }
                }
                &.share {
                    opacity: 1;
                    transform: translateY(0);
                    &:hover {
                        background-color: #1F1F1F;
                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
            .socials-left__item:nth-of-type(1) {
                transition-delay: .4s;
            }
            .socials-left__item:nth-of-type(2) {
                transition-delay: .3s;
            }
            .socials-left__item:nth-of-type(3) {
                transition-delay: .2s;
            }
            .socials-left__item:nth-of-type(4) {
                transition-delay: .1s;
            }
            &.show {
                .socials-left__item {
                    opacity: 1;
                    transform: translateY(0);
                    &.share {
                        opacity: 1;
                    }
                }
            }
        }
    }
    @media(min-width: 1350px) {
        display: block;
    }
    &__item {
        width: 60px;
        height: 60px;
        border: 1px solid #DCDCDC;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 8px 8px;
        transition: .3s all ease-in-out;
        background-color: #F3F3F3;
        &.share {
            background: #1F1F1F;
        }
        &:hover {
            background-color: #1F1F1F;
            svg {
                path {
                    fill: #fff;
                }
            }
        }
        svg {
            path {
                transition: .3s all ease-in-out;
            }
        }
    }
}
