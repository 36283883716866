.science-clubs {
    margin-bottom: 120px;
    &__description {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding: 27px;
        border: 3px solid #1F1F1F;
        @media(max-width: 600px) {
            flex-wrap: wrap;
        }
        @media(max-width: 440px) {
            padding: 13px;
        }
        &-image {
            width: 124px;
            height: 124px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #F62023;
            max-width: 124px;
            flex: 0 0 124px;
            margin-right: 30px;
            @media(max-width: 600px) {
                margin: 0 auto 20px auto;
            }
        }
        &-text {
            font-size: 18px;
            line-height: 30px;
            @media(max-width: 600px) {
                max-width: 100%;
                flex: 0 0 100%;
            }
            @media(max-width: 440px) {
                font-size: 16px;
                line-height: 26px;
            }
            a {
                color: #CD1719;
            }
        }
    }
    &__item {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 10px;
        &-title {
            font-size: 23px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 10px;
            @media(max-width: 500px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        &-address {
            font-size: 15px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 19.2px;
        }
        &-phone {
            font-size: 15px;
            line-height: 25px;
        }
        &-bottom {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            a {
                color: #CD1719;
                font-size: 15px;
                line-height: 25px;
            }
            &-email {
                display: flex;
                align-items: center;
                margin-right: 15px;
                @media(max-width: 600px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                img {
                    margin-right: 7px;
                }
            }
            &-www {
                display: flex;
                align-items: center;
                margin-right: 15px;
                @media(max-width: 600px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-left: 0px;
                }
                img {
                    margin-right: 7px;
                }
            }
            &-facebook {
                display: flex;
                align-items: center;
                @media(max-width: 600px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-left: 0px;
                }
                img {
                    margin-right: 7px;
                }
            }
        }
    }
}