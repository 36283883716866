.newsletter {
    background: linear-gradient(45deg, rgba(31,31,31,1) 0%,rgba(10,40,80,1) 100%);
    color: #fff;
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 50px;
    &__top {
        &-breadcrumb {
            margin-bottom: 27px;
        }
        &-title {
            margin-top: 0px;
            margin-bottom: 30px;
            font-size: 40px;
            line-height: 53px;
            text-transform: uppercase;
            font-weight: 400;
        }
    }
    &__image-hidden {
        margin: 0 auto 30px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 100%;
            height: auto;
        }
        @media(min-width:758px) {
            display: none;
        }
    }
    &__middle {
        &-response {
            font-size: 15px;
            line-height: 18px;
            color: #fff;
            font-weight: 700;
            padding: 36px 35px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.success {
                background-color: #6CC981;
            }
            &.error {
                background-color: #CD292A;
            }
            a {
                color: #fff;
                text-decoration: underline;
            }

        }
        &-description {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 35px;
        }
        &-form {
            &-input {
                margin-bottom: 21px;
                input {
                    text-align: center;
                    font-size: 20px;
                    padding: 30px 15px;
                    max-width: 100%;
                    width: 100%;
                    border-radius: 204px;
                    background-color: rgba(#fff,.2);
                    font-family: $font-main;
                    color: #fff;
                    border: none;
                }
            }
            &-description {
                font-size: 18px;
                line-height: 25px;
                font-weight: 700;
                margin-bottom: 17px;
            }
            &-rules {
                &-checkbox {
                    .form-check {
                        position: relative;
                        display: flex;
                        align-items: flex-start;
                        margin-bottom: 35px;
                    }
                    input {
                        flex: 0 0 23px;
                        width: 23px;
                        height: 23px;
                        border: none;
                        opacity: 0;
                        margin-right: 13px;
                        &:checked {
                            & + label {
                                &::after {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    label {
                        font-size: 11px;
                        line-height: 17px;
                        color: rgba(#fff,.5);
                        &::before {
                            content: '';
                            width: 23px;
                            height: 23px;
                            border-radius: 5px;
                            border: 2px solid #464E5A;
                            background-color: transparent;
                            position: absolute;
                            left: 2px;
                            top: 1px;
                            pointer-events: none;
                        }
                        &::after {
                            content: '';
                            background-image: url('/front/images/Elportal/checkbox.png');
                            background-size: 14px 10px;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 23px;
                            height: 23px;
                            position: absolute;
                            left: 5px;
                            top: 2px;
                            opacity: 0;
                            transition: .3s all ease-in-out;
                            pointer-events: none;
                        }
                    }
                }
            }
            &-submit {
                margin-bottom: 39px;
                button {
                    max-width: 100%;
                    width: 100%;
                    background-color: #CD1719;
                    text-align: center;
                    padding: 30px 15px;
                    color: #fff;
                    border-radius: 152px;
                    text-transform: uppercase;
                    font-size: 17px;
                    line-height: 21px;
                    font-weight: 700;
                    letter-spacing: 0.100em;
                    border: none;
                    cursor: pointer;
                }
            }
            &-second-description {
                font-size: 11px;
                line-height: 17px;
                opacity: 0.5;
                margin-bottom: 147px;
                .close {
                    display: none;
                }
                .btn {
                    text-transform: uppercase;
                    font-weight: 700;
                    cursor: pointer;
                }
                &.open {
                    .close {
                        display: inline;
                    }
                    .open {
                        display: none;
                    }
                    .dots {
                        display: none;
                    }
                }
            }
        }
        &-unsubscribe {
            margin-bottom: 41px;
            &-title {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 21px;
                text-align: center;
            }
            &-bottom {
                display: flex;
                align-items: center;
                justify-content: center;
                input[type="email"] {
                    width: 380px;
                    text-align: center;
                    height: 50px;
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                    border: 1px solid rgba(#fff,.2);
                    background-color: transparent;
                    color: rgba(#fff,.7);
                    font-size: 13px;
                    &::placeholder {
                        color: rgba(#fff,.7);
                    }
                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: rgba(#fff,.7);
                    }

                    ::-ms-input-placeholder { /* Microsoft Edge */
                        color: rgba(#fff,.7);
                    }
                }
                button {
                    width: 134px;
                    height: 50px;
                    background-color: #3D3D3D;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    color: #fff;
                    font-family: $font-main;
                    font-size: 13px;
                    letter-spacing: 0.050em;
                    border: none;
                    cursor: pointer;
                    text-transform: uppercase;
                }
            }
        }
        &-image {
            display: none;
            justify-content: center;
            @media(min-width: 768px) {
                display: flex;
            }
            img {
                filter: drop-shadow(0 20px 99px rgba(#000000,.4));
            }
        }
    }
}
