.top-bar {
    position: relative;
    width: 100%;
    @media(max-width: 600px) {
        display: none;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        height: 42px;
    }
    &__title {
        font-size: 11px;
        line-height: 23px;
        letter-spacing: 0.050em;
        margin-right: 21px;
        text-transform: uppercase;
    }
    &__items {
        display: flex;
        align-items: center;
        &-item {
            margin-right: 21px;
            display: flex;
            align-items: center;
            &.automatyka {
                margin-top: 3px;
            }
        }
    }
}

.header {
    padding: 35px 0 7px 0;
    box-shadow: 0 3px 50px rgba(0,0,0,.16);
    background-color: #FFFFFF;
    position: relative;
    left: 0;
    width: 100%;
    z-index: 10;
    // transition: .3s margin ease-in-out;
    margin: 0 0 30px;
    @media(max-width: 600px) {
        top: 0px;
    }
    &__sticky {
        top: -86px;
        transition: .2s all ease-in-out;
        &.sticky {
            padding-top: 9px;
            padding-bottom: 4px;
            top: -86px;
            position: fixed;
            .header__logo-text {
                display: none;
            }
            .header__menu {
                margin-top: 8px;
            }
            .header__right {
                margin-top: 8px;
            }
            .header__logo {
                margin-bottom: 0px;
                margin-top: 8px;
                svg {
                    width: 130px;
                }
            }
            .header__right-search-close {
                top: 10px;
            }
            .header__right-search-submit {
                top: 10px;
            }
            .header__right-search input {
                padding: 9px 15px;
            }
            .header__right-search {
                top: -14px;
            }
            .header__tags {
                margin-top: 12px;
            }
        }
        &.header-show {
            top: 0;
        }
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
    &__burger {
        flex: 1;
        margin-top: 16px;
        display: none;
        cursor: pointer;
        @media(max-width: 1200px) {
            display: block;
        }
        @media(max-width: 360px) {
            margin-top: 5px;
        }
    }
    &__menu {
        flex: 1;
        text-align: left;
        margin-top: 5px;
        @media(max-width: 1200px) {
            display: none;
        }
        ul {
            font-size: 0px;
            & > li {
                display: inline-block;
                font-size: 15px;
                line-height: 18px;
                font-weight: 700;
                margin-right: 20px;
                a {
                    position: relative;
                    transition: .3s all ease-in-out;
                    &::after {
                        content: '';
                        width: 100%;
                        height: 2px;
                        background-color: #CD1719;
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        opacity: 0;
                        transition: .3s all ease-in-out;
                    }
                    &:hover {
                        color: #CD1719;
                    }
                }
                &.active {
                    a {
                        position: relative;
                        &::after {
                            opacity: 1;
                        }
                    }
                }
                @media(max-width: 1100px) {
                    margin-right: 15px;
                }
                &:last-of-type {
                    margin-right: 0px;
                }
                & > ul {
                    display: none;
                }

            }
        }
    }
    &__logo {
        text-align: center;
        svg {
            width: 181px;
            margin-bottom: 5px;
            @media(max-width: 500px) {
                width: 130px;
            }
        }
        &-text {
            font-size: 12px;
            line-height: 15px;
            color: #898989;
            margin: 0;
            font-weight: 500;
            max-width: 100%;
            width: 100%;
            @media(max-width: 440px) {
                display: none;
            }
        }
    }
    &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        flex: 1;
        margin-top: 5px;
        @media(max-width: 360px) {
            margin-top: 5px;
        }
        &-menu {
            @media(max-width: 1200px) {
                display: none;
            }
            ul {
                font-size: 0;
                & > li {
                    display: inline-block;
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: 700;
                    padding: 0 10px;
                    a {
                        position: relative;
                        transition: .3s all ease-in-out;
                        &::after {
                            content: '';
                            width: 100%;
                            height: 2px;
                            background-color: #CD1719;
                            position: absolute;
                            bottom: -5px;
                            left: 0;
                            opacity: 0;
                            transition: .3s all ease-in-out;
                        }
                        &:hover {
                            color: #CD1719;
                        }
                    }
                    &.active {
                        a {
                            position: relative;
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                    @media(max-width: 1100px) {
                        padding: 0 7.5px;
                    }
                    & > ul {
                        display: none;
                    }
                    &:last-child {
                        a {
                            color: $color-white;
                            background-color: #1F1F1F;
                            display: inline-block;
                            padding: 5px 10px;
                            border-radius: 189px;
                        }
                    }
                }
            }
        }
        &-icons {
            display: flex;
            margin-top: 3px;
            position: relative;
            height: 22px;
            @media(max-width: 360px) {
                margin-top: 0px;
            }
            .search {
                margin-right: 20px;
                margin-left: 10px;
                cursor: pointer;
                @media(max-width: 1200px) {
                    margin-right: 0px;
                }
            }
            .burger {
                cursor: pointer;
                @media(max-width: 1200px) {
                    display: none;
                }
            }
        }
        &-search {
            position: absolute;
            top: -20px;
            right: 0;
            opacity: 0;
            transition: .3s all ease-in-out;
            pointer-events: none;
            &-close {
                position: absolute;
                right: 15px;
                top: 20px;
                cursor: pointer;
            }
            &-submit {
                background: none;
                border: none;
                position: absolute;
                right: 37px;
                top: 19px;
                cursor: pointer;
            }
            &.show {
                opacity: 1;
                pointer-events: all;
            }
            input {
                padding: 19px 26px;
                width: 312px;
                border: 2px solid #898989;
                font-family: $font-main;
            }
        }
    }
    &__tags {
        max-width: 100%;
        width: 100%;
        position: relative;
        margin-top: 27px;
        .swiper-wrapper {
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-right: 100px;
        }
        .swiper-slide {
            width: auto;
        }
        &-item {
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            background-color: #E8EBEE;
            padding: 4px 5px;
            text-transform: uppercase;
            letter-spacing: 0.020em;
            cursor: pointer;
            transition: .3s all ease-in-out;
            display: block;
            &:hover {
                background-color: #CD1719;
                color: #fff;
            }
            &.active {
                background-color: #CD1719;
                color: #fff;
            }
        }
        &-all {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0,-50%);
            z-index: 2;
            background-color: #898989;
            padding: 7px 11px 7px 10px;
            border-top-left-radius: 25px;
            border-bottom-left-radius: 25px;
            color: #fff;
            font-size: 12px;
            line-height: 15px;
            font-weight: 600;
            text-transform: uppercase;
            cursor: pointer;
            transition: .3s all ease-in-out;
            &:hover {
                background-color: #CD1719;
                color: #fff;
            }
            &.active {
                background-color: #CD1719;
                color: #fff;
            }
            img {
                margin-right: 5.4px;
            }
        }
        &-shadow {
            position: absolute;
            right: 85px;
            top: 50%;
            transform: translate(0,-50%);
            width: 67px;
            height: 27px;
            background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            z-index: 1;
            pointer-events: none;
        }
    }
}
.burger-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(31,31,31,.9);
    transition: .3s all ease-in-out;
    z-index: 50;
    pointer-events: none;
    opacity: 0;
    overflow: auto;
    &.show {
        opacity: 1;
        pointer-events: all;
        .burger-menu__wrapper {
            transform: translate(0,0);
            opacity: 1;
        }
    }
    &__wrapper {
        min-height: 100vh;
        max-width: 400px;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background-color: #fff;
        padding-top: 28px;
        display: flex;
        align-content: space-between;
        flex-wrap: wrap;
        transition: .3s all ease-in-out;
        transform: translate(100%,0);
        opacity: 0;
        &-close {
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
        }
        &-top {
            max-width: 100%;
            width: 100%;
        }
        &-logo {
            text-align: center;
            padding-bottom: 33px;
            margin-bottom: 39px;
            border-bottom: 1px solid #DCDCDC;
            display: block;
        }
        &-partners {
            padding-left: 50px;
            margin-bottom: 100px;
            margin-top: 16px;
            &-title {
                font-size: 11px;
                line-height: 23px;
                letter-spacing: 0.050em;
                padding-bottom: 10px;
                margin-bottom: 18px;
                border-bottom: 1px solid #E9E9E9;
                text-transform: uppercase;
            }
            &-item {
                display: block;
                margin-bottom: 29px;
                &.ep {
                    img {
                        width: 104px;
                    }
                }
                &.eb2b {
                    img {
                        width: 140px;
                    }
                }
                &.ab2b {
                    img {
                        width: 154px;
                    }
                }
            }
        }
        &-menu {
            padding-left: 50px;
            ul {
                & > li {
                    display: block;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 700;
                    margin-bottom: 26px;
                    transition: .3s all ease-in-out;
                    &.page-327, &.page-330 {
                        font-weight: 300;
                    }
                    &:hover {
                        color: #CD1719;
                    }
                    &.active {
                        color: #CD1719;
                    }
                    & > ul {
                        display: none;
                    }
                    &.page-395 {
                        font-weight: 400;
                    }
                }
                &:last-child {
                    li {
                        &:last-child {
                            a {
                                display: inline-block;
                                font-size: 18px;
                                font-weight: 400;
                                color: $color-white;
                                background-color: #1F1F1F;
                                padding: 8px 16px;
                                border-radius: 189px;
                            }
                        }
                    }
                }
            }
        }
        &-magazines {
            min-height: 250px;
            height: 100%;
            width: 100%;
            position: relative;
            background-color: #CD1719;
            &-item {
                z-index: 2;
                position: relative;
                margin-top: 39px;
                display: block;
                filter: drop-shadow(0 20px 50px rgba(#000,.4));
                &:nth-of-type(1) {
                    margin-left: 24px;
                    z-index: 5;
                }
                &:nth-of-type(2) {
                    position: absolute;
                    top: -53px;
                    left: 126px;
                    z-index: 4;
                }
                &:nth-of-type(3) {
                    position: absolute;
                    top: -117px;
                    left: 232px;
                    z-index: 3;
                }
            }
            &::after {
                content: '';
                position: absolute;
                top: -24px;
                left: 0;
                width: 0;
                height: 0;
                border-top: 24px solid transparent;
                border-left: 400px solid #fff;
                border-bottom: 45px solid transparent;
            }
        }
    }
}
