* {
    -webkit-tap-highlight-color:transparent;
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
*:active, *:focus {
    outline:none
}
input::-moz-focus-inner, select::-moz-focus-inner, option::-moz-focus-inner, textarea::-moz-focus-inner {
    border:0;
    outline:none
}
img {
    max-width:100%;
    height:auto;
}
picture {
    max-width: 100%;
    height: auto;
}
html, body {
    margin:0;
    padding:0;
    font-weight: 400;
    color: #1F1F1F;
    font-family: $font-main;
    scroll-behavior: smooth;
}
body {
    position: relative;
    text-transform: none;
    background-color: #F3F3F3;
    // padding-top: 194px;
    transition: .3s padding-top ease-in-out;
    &.sticky-header {
        // padding-top: 108px;
    }
}
ul, ol {
    padding:0;
    margin:0;
    list-style:none
}
a {
    color: inherit;
    text-decoration:none;
    outline:none
}

.d-none {
    display: none !important;
}
.subpage-back {
    display: inline-flex;
    align-items: center;
    font-size: 17px;
    line-height: 23px;
    font-weight: 500;
    img {
        margin-right: 10px;
    }
}
.subpage-title {
    font-size: 30px;
    line-height: 43px;
    margin-bottom: 30px;
    text-transform: uppercase;
    @media(min-width: 768px) {
        font-size: 35px;
        line-height: 48px;
    }
    @media(min-width: 1024px) {
        font-size: 40px;
        line-height: 53px;
    }
    &.white {
        color: #fff;
    }
    &.lower-case {
        text-transform: none;
    }
}

.subpage-description {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 40px;
}

.section-title {
    font-size: 18px;
    letter-spacing: 0.075em;
    padding-bottom: 8px;
    margin-bottom: 30px;
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-weight: 300;
    &.line {
        display: block;
        padding-bottom: 0px;
        &::after {
            display: none;
        }
        &.gray {
            .section-title__line {
                background-color: #F3F3F3;
            }
        }
        .section-title__text {
            display: inline-block;
            position: relative;
            padding-bottom: 8px;
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                max-width: 100%;
                height: 3px;
                background-color: #CD1719;
                width: 100%;
                z-index: 1;
            }
        }
        .section-title__line {
            position: absolute;
            height: 1px;
            bottom: 1px;
            left: 0;
            background-color: #fff;
            width: 100%;
        }
    }
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 100%;
        height: 3px;
        background-color: #CD1719;
        width: 100%;
    }
}

.subpage-description {
    font-size: 18px;
    line-height: 25px;
}

.background-image {
    display: block;
    position: absolute;
    scale: 7;
    transform: scale(7);
    filter: brightness(50%) blur(10px);
    backdrop-filter: blur(10px) brightness(0.5) blur(10px);
    @media(min-width: 1025px) {
        scale: 3;
        transform: scale(3);
    }
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-34 {
    margin-bottom: 34px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-26 {
    margin-bottom: 26px;
}

.mb-60 {
    margin-bottom: 60px;
}

.ls-50 {
    letter-spacing: 0.050em;
}
.ls-20 {
    letter-spacing: 0.020em;
}
.red {
    color: #CD1719;
}

.d-block {
    display: block;
}

.space-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.background-color {
    background-color: #F3F3F3;
}

.pt-15 {
    padding-top: 15px;
}
