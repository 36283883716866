.red-module {
    background-image: url('/front/images/Elportal/red-module-background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 50px 15px 60px 15px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -webkit-linear-gradient(45deg, rgba(31,31,31,0.96) 0%,rgba(205,23,25,0.96) 100%);
    }
    &__logo {
        text-align: center;
        z-index: 1;
        position: relative;
        opacity: 0.5;
        margin-bottom: 30px;
    }
    &__title {
        position: relative;
        z-index: 1;
        color: #fff;
        text-align: center;
        font-size: 22px;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 30px;
        @media(max-width: 768px) {
            font-size: 20px;
            line-height: 23px;
        }
    }
    &__text {
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        max-width: 957px;
        margin: 0 auto;
        z-index: 1;
        position: relative;
        @media(max-width: 768px) {
            font-size: 16px;
            line-height: 23px;
        }
        p {
            margin-top: 0px;
            margin-bottom: 25px;
        }
    }
}