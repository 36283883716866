.sticky-newsletter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, rgba(10,40,80,1) 0%,rgba(31,31,31,1) 100%);
    color: #fff;
    z-index: 10;
    padding-top: 15px;
    padding-bottom: 15px;
    @media(min-width: 768px) {
        height: 110px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    &.hidden {
        display: none;
    }
    &__image {
        display: none;
        position: absolute;
        left: 27px;
        top: -31px;
        @media(min-width: 1830px) {
            display: block;
        }
    }
    &__middle {
        height: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media(min-width: 768px) {
            flex-wrap: nowrap;
        }
        .container {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            @media(max-width: 1365px) {
                padding-right: 75px;
            }
            @media(max-width: 575px) {
                padding-right: 45px;
            }
        }
        &-logo {
            display: none;
            align-items: center;
            margin-right: 20px;
            @media(min-width: 1500px) {
                max-width: 20%;
                flex: 0 0 20%;
                margin-right: 70px;
            }
            @media(min-width: 1100px) {
                display: flex;
            }
            &-icon {
                width: 69px;
                height: 69px;
                border-radius: 50%;
                background-color: #F62023;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &-text {
                font-size: 30px;
                line-height: 37px;
                letter-spacing: -0.045em;
                font-weight: 700;
                margin-left: -14px;
            }
        }
        &-description {
            max-width: 100%;
            font-size: 17px;
            line-height: 28px;
            text-align: center;
            margin-bottom: 15px;
            width: 100%;
            @media(min-width: 768px) {
                text-align: left;
                margin-bottom: 0px;
                width: auto;
            }
            .bold {
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        &-btn {
            width: 100%;
            max-width: 150px;
            margin: 0 auto;
            @media(min-width: 768px) {
                max-width: 200px;
                margin: 0;
            }
            &-wrapper {
                width: 100%;
                height: 60px;
                border-radius: 272px;
                filter: drop-shadow(0 25px 99px rgba(#000000,.5));
                background-color: #fff;
                font-size: 15px;
                line-height: 21px;
                font-weight: 700;
                letter-spacing: 0.100em;
                text-transform: uppercase;
                color: #1F1F1F;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                @media(min-width: 768px) {
                    margin: 0;
                }
            }
        }
    }
    &__close {
        position: absolute;
        cursor: pointer;
        width: 100%;
        max-width: 25px;
        right: 10px;
        top: 10px;
        @media(min-width: 576px) {
            top: 15px;
            right: 15px;
            max-width: 38px;
        }
        @media(min-width: 768px) {
            top: 50%;
            transform: translate(0,-50%);
            max-width: 48px;
        }
        @media(min-width: 1366px) {
            right: 28.4px;
        }
        svg {
            max-width: 100%;
            filter: drop-shadow(0 5px 15px rgba(#000000,.52));
        }
    }
}
